
module controllers {
    export module customs {
        interface IClearingInstructionReconDetailList extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        interface IClearingInstructionReconDetailListParams extends ng.ui.IStateParamsService {
        }

        export class clearingInstructionReconDetailListCtrl {

            static $inject = [
                "$scope",
                "$stateParams",
                "$rootScope",
                "customsService",
                "classificationValueService",
                "$timeout",
                "generalService",
                "$q",
                "$state",
                "entityService",
                "isInbound"
            ];

            cusSearch: interfaces.customs.IClearingInstructionReconHeaderSearch = <interfaces.customs.IClearingInstructionReconHeaderSearch>{
                EntityId: -1,
                StatusId: 0
            };
            
            searchAccordian: boolean = false;

            showGrid: boolean = false;
            IsLoading: boolean = false;

            IsInbound: boolean = false;

            //Entity Variables
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>(),
                CustomsStatusList: Array<interfaces.applicationcore.IDropdownModel>()
            };

            apiCusList: uiGrid.IGridApi;
            selectedCus: uiGrid.IGridRow;

            constructor(
                private $scope: IClearingInstructionReconDetailList,
                private $stateParams: IClearingInstructionReconDetailListParams,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private customsService: interfaces.customs.ICustomsService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private isInbound: boolean) {

                this.IsInbound = isInbound;

                this.loadControls();

                $timeout(() => {
                    this.showGrid = true;
                });
            }

            loadControls() {

                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.getCurrentEntity(),
                    this.loadEntities(),
                    this.getCustomsStatusList()
                ]

                this.$q.all(controlPromises).then((data) => {
                    this.$scope.ShowGrid = true;
                });
            }

            getCurrentEntity(): ng.IPromise<boolean> {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            //Loading Entities
            loadEntities(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                    deferred.resolve(true);
                    
                }, (failureData) => {
                    
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }
            
            getCustomsStatusList() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.CustomsRecon.CustomsStatus
                }, (resultList) => {
                    this.dropdownsObject.CustomsStatusList = resultList;
                }).$promise;
            }

            search_click() {

                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                if (this.apiCusList !== undefined) {
                    this.apiCusList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiCusList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.cusSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiCusList) {

                    var grid = this.apiCusList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.customsService.GetCustomsReconListExcel(params).then(() => {
                    this.$scope.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gCusList.data = [];
                    this.gCusList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            gCusList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "Id",
                    displayName: "Id",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.clearingInstructionReconDetailListCtrl.editCustom(row.entity.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                },
                {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "ConsignmentNumber",
                    displayName: "Consignment",
                    field: "ConsignmentNumber",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "Origin",
                    displayName: "Origin",
                    field: "Origin",
                    width: 250,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "CPC",
                    displayName: "CPC",
                    field: "CPC",
                    width: 250,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "MRN",
                    displayName: "MRN",
                    field: "MRN",
                    width: 250,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "Status",
                    displayName: "Status",
                    field: "Status",
                    width: 150,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }]
            };

            registerGridApi(gridApi: uiGrid.IGridApi) {

                this.showAll_click();
                this.apiCusList = gridApi;

                this.apiCusList.core.on.filterChanged(this.$scope, () => { this.CusListFilterChange(gridApi) });
                this.apiCusList.core.on.sortChanged(this.$scope, () => { this.CusListShortChange(gridApi) });
                this.apiCusList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.CusListPageChange(newPage, pageSize) });

                this.apiCusList.selection.on.rowSelectionChanged(this.$scope, (selected) => { this.selectedCus = selected; });

                this.$timeout(() => {
                    if (this.selectedCus)
                        this.apiCusList.selection.selectRow(this.selectedCus.entity);
                });
            }

            editCustom(clearingIntructionReconId: number) {
                this.$state.go("auth.CustomsRecon.Update", { cusId: Number(clearingIntructionReconId) });
            }

            CusListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            CusListShortChange(gridApi) {
                this.DoSearch();
            }

            CusListFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }   

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.cusSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiCusList) {

                    var grid = this.apiCusList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }
                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.IsLoading = true;
                this.customsService.GetCustomsReconList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gCusList.data = data;

                    if (data[0]) {
                        this.gCusList.totalItems = data[0].NumRecords;
                    } else {
                        this.gCusList.totalItems = 0;
                    }

                }, (data) => {
                    this.IsLoading = false;
                    this.gCusList.data = [];
                    this.gCusList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            close() {
                this.$state.go("^");
            }
            
        };

        angular.module("app").controller("clearingInstructionReconDetailListCtrl", controllers.customs.clearingInstructionReconDetailListCtrl);
    }
}